// import * as Sentry from "@sentry/nextjs"
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function handleAxiosError(e: any): string {
  switch (e?.data?.error) {
    case 'wrong_credentials':
      return 'Login ou senha incorretos'
    case 'password_needs_refresh':
      return 'Sua senha expirou. Solicite uma nova senha'
    default:
      // Sentry.captureException(e)
      console.error({ e })
      return 'Algo inesperado aconteceu'
  }
}

export const VALIDATE_PHONE = (value: string | undefined | null) =>
  !value || /^\(\d{2}\) \d{4,5}-\d{4}$/.test(value)
export const VALIDATE_FEDERAL_NUMBER = (value: string | undefined | null) =>
  !value || /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value)
export const VALIDATE_EMAIL = (value: string | undefined | null) =>
  !value || /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)

export function debounce<T extends (...args: Parameters<T>) => void>(
  this: ThisParameterType<T>,
  fn: T,
  delay = 400,
) {
  let timer: ReturnType<typeof setTimeout> | undefined
  return (...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn.apply(this, args), delay)
  }
}
